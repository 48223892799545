import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { useStaticQuery, graphql } from "gatsby"

export default ({ name }) => {
  const data = useStaticQuery(graphql`
    {
      allFile {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  const targetEdge = data.allFile.edges.find(edge => edge.node.name === name)
  return targetEdge && targetEdge.node.childImageSharp ? (
    <GatsbyImage image={getImage(targetEdge.node)} alt={targetEdge.node} />
  ) : null
}
