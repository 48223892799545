import React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"

const omit = text => len => ellipsis =>
  text.length >= len
    ? text.slice(0, len - ellipsis.length) + ellipsis
    : text

const NextPrevLink = ({ title, postsId, direction } ) => {

    if (direction === "prev"){
      return (
          <button tw="shadow-md border rounded-md w-full md:w-1/2 my-4 text-left mr-2">
          <Link to={`/posts/${postsId}`} itemProp="url">
            <div tw="flex flex-wrap justify-start p-2">
              <div tw="ml-4 truncate">
                <p tw="text-sm text-gray-500">＜ PREV</p>
                <span>{title}</span>
              </div>
            </div>
          </Link>
        </button>
      )
    } else {
      return (
        <button tw="shadow-md border rounded-md w-full md:w-1/2 my-4 text-right ml-2">
        <Link to={`/posts/${postsId}`} itemProp="url">

        <div tw="flex flex-wrap justify-end p-2">
        <div tw="mr-4 truncate">
          <p tw="text-sm text-gray-500">NEXT ＞</p>
          <span>{title}</span>
        </div>
        </div>
        </Link>
      </button>

      );
    }
  }

  
export default NextPrevLink;