import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw from "twin.macro"

const Li = tw.li`
  px-3
`

const ShareLinks = ({ postsId, title }) => {
  const twitterLink = postsId
    ? `https://twitter.com/intent/tweet?url=https://manpukuhuhu.com/posts/${postsId}&text=${title}｜manpuku 茨城夫婦のグルメブログ　&via=manpukuhuhu&hashtags=茨城グルメ,グルメブログ,茨城夫婦,manpuku`
    : `https://twitter.com/manpukuhuhu`

  return (
    <>
      <div tw="mx-auto ">
        <ul tw="flex-wrap flex xl:flex-col sticky top-0 py-10">
          <Li>
            <a
              href="https://www.instagram.com/manpukuhuhu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../assets/icons/Instagram_AppIcon_Aug2017.png"
                alt="Instagram"
                width="40"
                height="40"
              />
            </a>
          </Li>
          <Li>
            <a
              href={`${twitterLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="../assets/icons/twitter.png"
                alt="Twitter"
                width="40"
                height="40"
                tw="rounded"
              />
            </a>
          </Li>
          <Li>
            <a
              href={`https://b.hatena.ne.jp/entry/s/manpukuhuhu.com/posts/${postsId}`}
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="touch-counter"
              data-hatena-bookmark-width="40"
              data-hatena-bookmark-height="40"
              title="このエントリーをはてなブックマークに追加"
            >
              <img
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
                width="20"
                height="20"
                style={{ border: "none" }}
              />
            </a>
            <Helmet>
              <script
                type="text/javascript"
                src="//b.st-hatena.com/js/bookmark_button.js"
                charset="utf-8"
                async="async"
              />
            </Helmet>
          </Li>
          <Li>
            <a
              href={`http://getpocket.com/edit?url=https://manpukuhuhu.com/posts/${postsId}&title=${title}`}
              rel="nofollow noopener noreferrer"
              target="_blank"
            >
              <StaticImage
                src="../assets/icons/Getpocket.png"
                alt="Getpocket"
                width="40"
                height="40"
              />
            </a>
          </Li>
          <Li>
            <Link to="/rss.xml">
              <StaticImage
                src="../assets/icons/rss_icon.png"
                alt="RSS"
                width="40"
                height="40"
              />
            </Link>
          </Li>
        </ul>
      </div>
    </>
  )
}

export default ShareLinks
