import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import tw from "twin.macro"
import Iframe from "react-iframe"

import Iframy from "../components/Iframely"
import Layout from "../components/layout"
import ShareLinks from "../components/ShareLinks"
import SEO from "../components/seo"
// import { AnchorLink } from "gatsby-plugin-anchor-links";
import ImagesInPost from "../components/GatsbyImage"
import NextPrevLink from "../components/NextPrevLink"

import cheerio from "cheerio"

const replaceMedia = node => {
  if (node.name === "img") {
    const {
      attribs: { src },
    } = node
    const img = src.split("/").slice(-1)[0].split(".")[0]
    if (img !== null) {
      return <ImagesInPost name={img} />
    }
  }
}

const BlogPostTemplate = ({ data: { microcmsPosts: post }, pageContext }) => {
  const { next, previous } = pageContext

  const html = post.content
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#x2F;/g, "/")
    .replace(/<iframe>/g, "<Iframe>")
    .replace(/iframe>/g, "Iframe>")
    .replace(/<img/g, '<img width="100%"')
    .replace(/width="600"/g, 'width="100%"')
    .replace(/height="450"/g, 'height="400"')

  const $ = cheerio.load(html)
  const headings = $("h1, h2, h3").toArray()
  const toc = headings.map(data => ({
    text: data.children[0].data,
    id: data.attribs.id,
    name: data.name,
  }))

  const image = getImage(post.localImage)

  return (
    <Layout>
      <SEO
        title={post.title}
        ogpThumbnail={post.image.url}
        postsId={post.postsId}
      />
      <Iframy />
      <div tw="pr-0 md:pr-6">
        <picture>
          <GatsbyImage image={image} tw="rounded-lg w-full" alt="" />
        </picture>
        <p tw="pt-6 text-blue-900">
          <Link to="/">記事一覧</Link>
        </p>
        <div tw="flex flex-wrap flex-row-reverse my-5">
          <div tw="w-full xl:w-5/6 mb-5">
            <h1 tw="text-2xl md:text-4xl font-bold py-3">{post.title}</h1>
            <p tw="py-4">{post.publishedAt}</p>

            {toc.length > 0 && (
              <div tw="bg-gray-200 mt-4 mb-10 rounded-md">
                <h2 tw="p-4 font-bold text-lg">目次</h2>
                <ul tw="divide-y mx-3">
                  {toc.map(item => {
                    return (
                      <li tw="mb-0 p-4" key={`${item.id}`}>
                        <Link to={`/posts/${post.postsId}#${item.id}`}>
                          {item.text}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}

            <section
              className="article-body"
              // dangerouslySetInnerHTML={{
              //   __html: `${html}`,
              // }}
              // ></section>
            >
              {parse(html, { replace: replaceMedia })}
            </section>
          </div>
          <ShareLinks tw="w-full xl:w-1/6" {...post} />
        </div>

        <div tw="md:flex md:justify-between">
          {next == null ? null : <NextPrevLink {...next} direction="prev" />}
          {previous == null ? null : (
            <NextPrevLink {...previous} direction="next" />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPost($slug: String!) {
    microcmsPosts(postsId: { eq: $slug }) {
      author
      category
      content
      postsId
      id
      publishedAt(formatString: "YYYY/MM/DD", locale: "ja")
      tags {
        fieldId
        tag
      }
      title
      image {
        url
      }
      localImage {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
